import {Injectable} from '@angular/core';
import {CrudApiService} from '@core/crud/crud-api.service';
import {CrudEndPoint} from '@core/const';
import {Employee, EmployeeReference} from "../core/employee.model";
import {firstValueFrom} from "rxjs";
import {UserProfile, UserProfileRequest} from "../../user/core/user-profile.model";
import {ExportRequest} from "@core/models/excel-export.model";
import {ReportResponse} from "@core/models/report-details.model";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends CrudApiService<Employee, EmployeeReference> {
  constructor() {
    super(CrudEndPoint.EMPLOYEES)
  }

  uploadImage(file: File): Promise<string> {
    const formData = new FormData();
    formData.append('image', file);
    return firstValueFrom(this.apiService.postImage(this.getEndPoint() + '/uploadImage', formData));
  }

  deleteImage(imageName: string): Promise<boolean> {
    return firstValueFrom(this.apiService.delete(this.getEndPoint() + '/deleteImage/' + imageName));
  }

  resetPassword(employeeId: number): Promise<boolean> {
    return firstValueFrom(this.apiService.put(this.getEndPoint() + '/resetPassword' + '/' + employeeId, undefined));
  }

  async saveProfile(userProfileRequest: UserProfileRequest): Promise<void> {
    return (firstValueFrom(this.apiService.post(this.getEndPoint() + '/profile', userProfileRequest)));
  }

  async getUserProfile(): Promise<UserProfile> {
    return await firstValueFrom(this.apiService.get<UserProfile>(this.getEndPoint() + '/profile')) as UserProfile;
  }

  exportExcel(data: ExportRequest): Promise<ReportResponse> {
    return firstValueFrom(this.apiService.post(this.getEndPoint() + '/export', data))
  }
}
